
// OCT 22
import grouper from "../img/nmf/2021/oct/grouper.jpeg"
import esteem from "../img/nmf/2021/oct/esteem.jpeg"
import maxo from "../img/nmf/2021/oct/maxo.jpeg"
import jpeg from "../img/nmf/2021/oct/jpeg.jpeg"
import circuit from "../img/nmf/2021/oct/circuit.jpeg"
import helado from "../img/nmf/2021/oct/helado.jpeg"
import biffy from "../img/nmf/2021/oct/biffy.jpeg"

// JULY 23
import dave from "../img/nmf/2021/july/dave.jpeg"
import darkside from "../img/nmf/2021/july/darkside.jpeg"

// JULY 16
import clairo from "../img/nmf/2021/july/clairo.jpeg"
import yves from "../img/nmf/2021/july/yves.jpeg"
import midwife from "../img/nmf/2021/july/midwife.jpeg"
import willow from "../img/nmf/2021/july/willow.jpeg"

// JULY 9
import vince from "../img/nmf/2021/july/vince.jpeg"
import charlotte from "../img/nmf/2021/july/charlotte.jpeg"
import tkay from "../img/nmf/2021/july/tkay.jpeg"

// MAY 7
import iceage from "../img/nmf/2021/may/iceage.jpeg"
import czarface from "../img/nmf/2021/may/czarface.jpeg"
import squid from "../img/nmf/2021/may/squid.jpeg"
import alfie from "../img/nmf/2021/may/alfie.jpeg"

// APR 30
import dawn from "../img/nmf/2021/apr/dawn.jpeg"

// APR 2
import dryclean from "../img/nmf/2021/apr/dryclean.jpeg"
import agclub from "../img/nmf/2021/apr/agclub.jpeg"
import lafemme from "../img/nmf/2021/apr/lafemme.jpeg"

// MAR 26
import serpent from "../img/nmf/2021/mar/serpent.jpeg"
import floating from "../img/nmf/2021/mar/floating.jpeg"

// MAR 19
import batiste from "../img/nmf/2021/mar/batiste.jpeg"
import yeek from "../img/nmf/2021/mar/yeek.jpeg"

// MAR 12
import leanne from "../img/nmf/2021/mar/leanne.jpg"
import cee from "../img/nmf/2021/mar/cee.jpg"
import pino from "../img/nmf/2021/mar/pino.jpg"

// MAR 5
import genesis from "../img/nmf/2021/mar/genesis.jpg"
import arabstrap from "../img/nmf/2021/mar/arabstrap.jpg"

// FEB 26
import carnage from "../img/nmf/2021/feb/carnage.jpg"

// FEB 19
import tash from "../img/nmf/2021/feb/tash.jpg"
import cassandra from "../img/nmf/2021/feb/cassandra.jpg"

// FEB 12
import claud from "../img/nmf/2021/feb/claud.jpg"
import slowthai from "../img/nmf/2021/feb/slowthai.jpg"

// FEB 5
import weather from "../img/nmf/2021/feb/weather.jpg"
import bcnr from "../img/nmf/2021/feb/bcnr.jpg"

// JAN 29
import madlib from "../img/nmf/albums/jan/madlib.jpg"
import arlo from "../img/nmf/albums/jan/arlo.jpg"
import yasmin from "../img/nmf/albums/jan/yasmin.jpg"

// JAN 15
import shame from "../img/nmf/albums/jan/shame.jpg"
import hether from "../img/nmf/albums/jan/hether.jpg"

import XX_IMAGE_XX from "../img/nmf/placeholder.jpg"

/*

  {
    active: true,
    date: {
      month: XXX,
      day: XXX,
      year: XXX,
    },
    albums: [
      {
        artist: "XX_ARTIST_XX",
        title: "XX_TITLE_XX",
        uri: "XX_URI_XX",
        genre: "XX_GENRE_XX",
        tracks: 99,
        runtime: 999,
        date: {
          month: 3,
          day: 19,
          year: 2021,
        },
        img: XXX_IMAGE_XXX,
        elite: false,
      },

    ],
  },

*/

/*

        {
          artist: "XX_ARTIST_XX",
          title: "XX_TITLE_XX",
          uri: "XX_URI_XX",
          genre: "XX_GENRE_XX",
          tracks: 99,
          runtime: 999,
          date: {
            month: 3,
            day: 19,
            year: 2021,
          },
          img: XXX_IMAGE_XXX,
          elite: false,
        },

*/

const ALBUM_DATA = [
  {
    active: true,
    date: {
      month: 10,
      day: 22,
      year: 2021,
    },
    albums: [
      {
        artist: "Self Esteem",
        title: "Prioritise Pleasure",
        uri: "spotify:album:6Q4b8zTe5hFiDaUxNOiBih",
        genre: "pop",
        tracks: 13,
        runtime: 45,
        date: {
          month: 10,
          day: 22,
          year: 2021,
        },
        img: esteem,
        elite: false,
      },
      {
        artist: "Grouper",
        title: "shade",
        uri: "spotify:album:1w3EOCPDwqWIiu2c1pHylJ",
        genre: "experimental / acoustic",
        tracks: 9,
        runtime: 35,
        date: {
          month: 10,
          day: 22,
          year: 2021,
        },
        img: grouper,
        elite: false,
      },
      {
        artist: "Maxo Kream",
        title: "WEIGHT OF THE WORLD",
        uri: "spotify:album:6DY8Tci3yCd6teXbxoPGN6",
        genre: "hip-hop",
        tracks: 16,
        runtime: 44,
        date: {
          month: 10,
          day: 22,
          year: 2021,
        },
        img: maxo,
        elite: false,
      },
      {
        artist: "JPEGMAFIA",
        title: "LP!",
        uri: "spotify:album:4inXqcdjG79gV4m2mU8dQh",
        genre: "hip-hop / experimental",
        tracks: 18,
        runtime: 49,
        date: {
          month: 10,
          day: 22,
          year: 2021,
        },
        img: jpeg,
        elite: false,
      },
      {
        artist: "Circuit Des Yeux",
        title: "-io",
        uri: "spotify:album:79TV8acvMeGKGbsBUKvwCI",
        genre: "alternative",
        tracks: 10,
        runtime: 41,
        date: {
          month: 10,
          day: 22,
          year: 2021,
        },
        img: circuit,
        elite: false,
      },
      {
        artist: "Helado Negro",
        title: "Far In",
        uri: "spotify:album:6VuufjwylS8CGaUcq3Vs4e",
        genre: "alternative / indie",
        tracks: 15,
        runtime: 68,
        date: {
          month: 10,
          day: 22,
          year: 2021,
        },
        img: helado,
        elite: false,
      },
      {
        artist: "Biffy Clyro",
        title: "The Myth of The Happily Ever After",
        uri: "spotify:album:79TV8acvMeGKGbsBUKvwCI",
        genre: "rock",
        tracks: 11,
        runtime: 50,
        date: {
          month: 10,
          day: 22,
          year: 2021,
        },
        img: biffy,
        elite: false,
      },
    ],
  },
  {
    active: true,
    date: {
      month: 7,
      day: 23,
      year: 2021,
    },
    albums: [
      {
        artist: "Dave",
        title: "We're All Alone In This Together",
        uri: "spotify:album:6HwzIlrCDq3WF9vMq8meqG",
        genre: "uk hip-hop",
        tracks: 12,
        runtime: 60,
        date: {
          month: 7,
          day: 23,
          year: 2021,
        },
        img: dave,
        elite: true,
      },
      {
        artist: "Darkside",
        title: "Spiral",
        uri: "spotify:album:09JglS9OxbpOkj5LXBsxYN",
        genre: "indie rock",
        tracks: 9,
        runtime: 52,
        date: {
          month: 7,
          day: 23,
          year: 2021,
        },
        img: darkside,
        elite: false,
      },

    ],
  },
  {
    active: true,
    date: {
      month: 7,
      day: 16,
      year: 2021,
    },
    albums: [
      {
        artist: "Clairo",
        title: "Sling",
        uri: "spotify:album:32ium7Cxb1Xwp2MLzH2459",
        genre: "indie rock",
        tracks: 12,
        runtime: 44,
        date: {
          month: 7,
          day: 16,
          year: 2021,
        },
        img: clairo,
        elite: false,
      },
      {
        artist: "Yves Tumor",
        title: "The Asymptotical World EP",
        uri: "spotify:album:2julo3Z5rNzSod7DoEuTz7",
        genre: "experimental rock",
        tracks: 6,
        runtime: 18,
        date: {
          month: 7,
          day: 15,
          year: 2021,
        },
        img: yves,
        elite: false,
      },
      {
        artist: "Midwife",
        title: "Luminol",
        uri: "spotify:album:4bga9m78vaVd0aXbUpfcHa",
        genre: "experimental rock",
        tracks: 6,
        runtime: 33,
        date: {
          month: 7,
          day: 16,
          year: 2021,
        },
        img: midwife,
        elite: false,
      },
      {
        artist: "Willow",
        title: "lately I feel EVERYTHING",
        uri: "spotify:album:1dg0gmrCaEbENVXpPIvi1m",
        genre: "rock",
        tracks: 11,
        runtime: 26,
        date: {
          month: 7,
          day: 16,
          year: 2021,
        },
        img: willow,
        elite: false,
      },
    ],
  },
    {
      active: true,
      date: {
        month: 7,
        day: 9,
        year: 2021,
      },
      albums: [
        {
          artist: "Vince Staples",
          title: "Vince Staples",
          uri: "spotify:album:2suR5CCbtL2Wq8ShFo8rFr",
          genre: "hip-hop",
          tracks: 10,
          runtime: 22,
          date: {
            month: 7,
            day: 9,
            year: 2021,
          },
          img: vince,
          elite: false,
        },
        {
          artist: "Charlotte Day Wilson",
          title: "ALPHA",
          uri: "spotify:album:1BFl2k9tZL0Jm6ebJHGQ5K",
          genre: "neo-soul",
          tracks: 11,
          runtime: 33,
          date: {
            month: 7,
            day: 9,
            year: 2021,
          },
          img: charlotte,
          elite: false,
        },
        {
          artist: "Tkay Maidza",
          title: "Last Year Was Weird, Vol. 3",
          uri: "spotify:album:5dtpyo5nsi19czgSeL9MHH",
          genre: "hip hop / r&b",
          tracks: 8,
          runtime: 22,
          date: {
            month: 7,
            day: 9,
            year: 2021,
          },
          img: tkay,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 5,
        day: 7,
        year: 2021,
      },
      albums: [
        {
          artist: "Iceage",
          title: "Seek Shelter",
          uri: "spotify:album:6wH4W7BWjBiXNM3xIccgOR",
          genre: "post-punk",
          tracks: 9,
          runtime: 51,
          date: {
            month: 5,
            day: 7,
            year: 2021,
          },
          img: iceage,
          elite: false,
        },
        {
          artist: "Czarface & MF Doom",
          title: "Super What?",
          uri: "spotify:album:4odeNH2Qe9DAxT3sC1qq9p",
          genre: "hip-hop",
          tracks: 10,
          runtime: 27,
          date: {
            month: 5,
            day: 7,
            year: 2021,
          },
          img: czarface,
          elite: false,
        },
        {
          artist: "Squid",
          title: "Bright Green Field",
          uri: "spotify:album:4z44Wir05QzQYSCs0KwsKX",
          genre: "post-punk",
          tracks: 11,
          runtime: 55,
          date: {
            month: 5,
            day: 7,
            year: 2021,
          },
          img: squid,
          elite: false,
        },
        {
          artist: "Alfie Templeman",
          title: "Forever Isn't Long Enough",
          uri: "spotify:album:25LHRDp5Wbc0fJilUkf04W",
          genre: "indie",
          tracks: 8,
          runtime: 30,
          date: {
            month: 5,
            day: 7,
            year: 2021,
          },
          img: alfie,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 4,
        day: 30,
        year: 2021,
      },
      albums: [
        {
          artist: "Dawn Richard",
          title: "Second Line",
          uri: "spotify:album:4ZZPaImVERqe30KPt1EOHv",
          genre: "XX_GENRE_XX",
          tracks: 16,
          runtime: 51,
          date: {
            month: 4,
            day: 30,
            year: 2021,
          },
          img: dawn,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 4,
        day: 2,
        year: 2021,
      },
      albums: [
        {
          artist: "Dry Cleaning",
          title: "New Long Leg",
          uri: "spotify:album:4oNy189uvEgnJKNLsWx9Zz",
          genre: "art rock",
          tracks: 10,
          runtime: 42,
          date: {
            month: 4,
            day: 2,
            year: 2021,
          },
          img: dryclean,
          elite: true,
        },
        {
          artist: "AG Club",
          title: "Fuck Your Expectations PT.1",
          uri: "spotify:album:5M25d4hSmTEN9WM1EtAsfv",
          genre: "hip-hop",
          tracks: 9,
          runtime: 27,
          date: {
            month: 4,
            day: 2,
            year: 2021,
          },
          img: agclub,
          elite: false,
        },
        {
          artist: "La Femme",
          title: "Paradigme",
          uri: "spotify:album:1u2Q5MFWptOdPpldjC0xO9",
          genre: "synth-pop",
          tracks: 15,
          runtime: 55,
          date: {
            month: 4,
            day: 2,
            year: 2021,
          },
          img: lafemme,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 3,
        day: 26,
        year: 2021,
      },
      albums: [
        {
          artist: "serpentwithfeet",
          title: "DEACON",
          uri: "spotify:album:15i2CVbvZzxiv9LfUkl2wU",
          genre: "art pop/r&b",
          tracks: 11,
          runtime: 29,
          date: {
            month: 3,
            day: 26,
            year: 2021,
          },
          img: serpent,
          elite: false,
        },
        {
          artist: "Floating Points & Pharoah Sanders",
          title: "Promises",
          uri: "spotify:album:3ShtO5VCYa3ctlR5uzLWBa",
          genre: "neoclassical",
          tracks: 9,
          runtime: 47,
          date: {
            month: 3,
            day: 26,
            year: 2021,
          },
          img: floating,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 3,
        day: 19,
        year: 2021,
      },
      albums: [
        {
          artist: "Jon Batiste",
          title: "WE ARE",
          uri: "spotify:album:6kHFkPvL2X3rZPuS3CEMUE",
          genre: "neo-soul",
          tracks: 13,
          runtime: 38,
          date: {
            month: 3,
            day: 19,
            year: 2021,
          },
          img: batiste,
          elite: false,
        },
        {
          artist: "Yeek",
          title: "Valencia",
          uri: "spotify:album:77Wq66HlKZgyKOtN5Whdbr",
          genre: "anti-pop",
          tracks: 10,
          runtime: 23,
          date: {
            month: 3,
            day: 19,
            year: 2021,
          },
          img: yeek,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 3,
        day: 12,
        year: 2021,
      },
      albums: [
        {
          artist: "Leanne Betasamosake Simpson",
          title: "Theory of Ice",
          uri: "spotify:album:5MMyebXVwA0Vzyvs1WvVS9",
          genre: "folk",
          tracks: 8,
          runtime: 32,
          date: {
            month: 3,
            day: 12,
            year: 2021,
          },
          img: leanne,
          elite: false,
        },
        {
          artist: "Central Cee",
          title: "Wild West",
          uri: "spotify:album:0aAVMtHuK9wX1mQozWvdSZ",
          genre: "UK Drill",
          tracks: 14,
          runtime: 38,
          date: {
            month: 3,
            day: 12,
            year: 2021,
          },
          img: cee,
          elite: false,
        },
        {
          artist: "Pino Palladino & Blake Mills & Another Person",
          title: "Notes With Attachments",
          uri: "spotify:album:5fsq4qeIWboRRPG7mGGfxr",
          genre: "Jazz Fusion",
          tracks: 8,
          runtime: 31,
          date: {
            month: 3,
            day: 12,
            year: 2021,
          },
          img: pino,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 3,
        day: 5,
        year: 2021,
      },
      albums: [
        {
          artist: "Genesis Owusu",
          title: "Smiling with No Teeth",
          uri: "spotify:album:7LI6T5cy6Et8RDZXCoCEtJ",
          genre: "???",
          tracks: 15,
          runtime: 53,
          date: {
            month: 3,
            day: 5,
            year: 2021,
          },
          img: genesis,
          elite: true,
        },
        {
          artist: "Arab Strap",
          title: "As Days Get Dark",
          uri: "spotify:album:5TE0TunG17dIPzrsGZAtjp",
          genre: "rock / slowcore",
          tracks: 11,
          runtime: 47,
          date: {
            month: 3,
            day: 5,
            year: 2021,
          },
          img: arabstrap,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 2,
        day: 26,
        year: 2021,
      },
      albums: [
        {
          artist: "Nick Cave & Warren Ellis",
          title: "CARNAGE",
          uri: "spotify:album:6xWNh0mUOdEg77U1f6oojz",
          genre: "experimental rock",
          tracks: 8,
          runtime: 40,
          date: {
            month: 2,
            day: 25,
            year: 2021,
          },
          img: carnage,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 2,
        day: 19,
        year: 2021,
      },
      albums: [
        {
          artist: "Cassandra Jenkins",
          title: "An Overview on Phenomenal Nature",
          uri: "spotify:album:1NzOdSkSNmBhhg72KlNcsE",
          genre: "indie rock",
          tracks: 7,
          runtime: 31,
          date: {
            month: 2,
            day: 19,
            year: 2021,
          },
          img: cassandra,
          elite: true,
        },
        {
          artist: "Tash Sultana",
          title: "Terra Firma",
          uri: "spotify:album:6CubvryZSNI5UsrftXJqhK",
          genre: "neo-soul",
          tracks: 14,
          runtime: 60,
          date: {
            month: 2,
            day: 19,
            year: 2021,
          },
          img: tash,
          elite: false,
        },
      ],
    },{
      active: false,
      date: {
        month: 2,
        day: 12,
        year: 2021,
      },
      albums: [
        {
          artist: "Claud",
          title: "Super Monster",
          uri: "spotify:album:1S0ZLW9sEo7yb5wKxYSeYp",
          genre: "indie pop",
          tracks: 13,
          runtime: 37,
          date: {
            month: 2,
            day: 12,
            year: 2021,
          },
          img: claud,
          elite: false,
        },
        {
          artist: "slowthai",
          title: "TYRON",
          uri: "spotify:album:7qE3WaOVAAIxgH8WtjbBBj",
          genre: "hip-hop",
          tracks: 14,
          runtime: 35,
          date: {
            month: 2,
            day: 12,
            year: 2021,
          },
          img: slowthai,
          elite: false,
        },
      ],
    },{
      active: false,
      date: {
        month: 2,
        day: 5,
        year: 2021,
      },
      albums: [
        {
          artist: "The Weather Station",
          title: "Ignorance",
          uri: "spotify:album:3KeR5BDM2giFr8zoSXBrgE",
          genre: "folk / art pop",
          tracks: 10,
          runtime: 40,
          date: {
            month: 2,
            day: 5,
            year: 2021,
          },
          img: weather,
          elite: false,
        },
        {
          artist: "Black Country, New Road",
          title: "For the first time",
          uri: "spotify:album:2PfgptDcfJTFtoZIS3AukX",
          genre: "post-punk",
          tracks: 6,
          runtime: 40,
          date: {
            month: 2,
            day: 5,
            year: 2021,
          },
          img: bcnr,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 1,
        day: 29,
        year: 2021,
      },
      albums: [
        {
          artist: "Madlib & Four Tet",
          title: "Sound Ancestors",
          uri: "spotify:album:5ftKZ7X2vjjJ1HFQYQn1UF",
          genre: "experimental",
          tracks: 16,
          runtime: 41,
          date: {
            month: 1,
            day: 29,
            year: 2021,
          },
          img: madlib,
          elite: false,
        },
        {
          artist: "Arlo Parks",
          title: "Collapsed in Sunbeams",
          uri: "spotify:album:42joEEymK7EIHODfNB4yug",
          genre: "pop",
          tracks: 12,
          runtime: 39,
          date: {
            month: 1,
            day: 29,
            year: 2021,
          },
          img: arlo,
          elite: false,
        },
        {
          artist: "Yasmin Williams",
          title: "Urban Driftwood",
          uri: "spotify:album:233gqBTj1syIvhppH8NV7w",
          genre: "acoustic",
          tracks: 10,
          runtime: 45,
          date: {
            month: 1,
            day: 29,
            year: 2021,
          },
          img: yasmin,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 1,
        day: 22,
        year: 2021,
      },
      albums: [
        {
          artist: "XXXXX",
          title: "XXXXX",
          uri: "XXXXX",
          genre: "XXXXX",
          tracks: 0,
          runtime: 0,
          date: {
            month: 1,
            day: 0,
            year: 2021,
          },
          img: shame,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 1,
        day: 15,
        year: 2021,
      },
      albums: [
        {
          artist: "shame",
          title: "drunk tank pink",
          uri: "spotify:album:7hl54HF6Nykh5IDrbqhiJX",
          genre: "alternative rock",
          tracks: 11,
          runtime: 41,
          date: {
            month: 1,
            day: 15,
            year: 2021,
          },
          img: shame,
          elite: false,
        },
        {
          artist: "hether",
          title: "sticky thumb",
          uri: "spotify:album:5fRFL33mS6f2EtH9RjOz68",
          genre: "indie rock",
          tracks: 8,
          runtime: 27,
          date: {
            month: 1,
            day: 15,
            year: 2021,
          },
          img: hether,
          elite: false,
        },
      ],
    },
    /* {
      active: false,
      date: {
        month: 1,
        day: 8,
        year: 2021,
      },
      albums: [
        {
          artist: "XXXXX",
          title: "XXXXX",
          uri: "XXXXX",
          genre: "XXXXX",
          tracks: XXX,
          runtime: XXX,
          date: {
            month: 1,
            day: XXX,
            year: 2021,
          },
          img: XXX,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: 1,
        day: 1,
        year: 2021,
      },
      albums: [
        {
          artist: "XXXXX",
          title: "XXXXX",
          uri: "XXXXX",
          genre: "XXXXX",
          tracks: XXX,
          runtime: XXX,
          date: {
            month: 1,
            day: XXX,
            year: 2021,
          },
          img: XXX,
          elite: false,
        },
      ],
    },
    {
      active: false,
      date: {
        month: XXX,
        day: XXX,
        year: 2020,
      },
      albums: [
        {
          artist: "XXXXX",
          title: "XXXXX",
          uri: "XXXXX",
          genre: "XXXXX",
          tracks: XXX,
          runtime: XXX,
          date: {
            month: XXX,
            day: XXX,
            year: 2020,
          },
          img: XXX,
          elite: false,
        },
        {
          artist: "XXXXX",
          title: "XXXXX",
          uri: "XXXXX",
          genre: "XXXXX",
          tracks: XXX,
          runtime: XXX,
          date: {
            month: XXX,
            day: XXX,
            year: 2020,
          },
          img: XXX,
          elite: false,
        },
      ],
    }, */
  ]

  export default ALBUM_DATA;